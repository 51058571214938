import {
  KWID,
  LOGAN,
  STEPWAY,
  DUSTER,
  OROCH,
  KOLEOS,
  KANGOO,
} from "Constants/CARS";

export const LV_HOST = "renaultlindavista";

const EMAILS = [
  "ana.favela@surman.com",
  "karina.hernandez@surman.com",
  "surman-renault-lindavista-adwords@leads.getsirena.com",
  "omar.vargas@surman.com",
  "yeloubox.monterrey@gmail.com",
];

export const LV_CONFIG = {
  emails: EMAILS,
  cars: [LOGAN, DUSTER, KOLEOS, KWID, OROCH, KANGOO, STEPWAY],
  banners: {
    version: "LV",
    list: [
      { group: "", type: "jpg", image: "Banner web_Duster" },
      { group: "", type: "jpg", image: "Banner web_Koleos" },
    ],
  },
  schedules: [],
  social: [
    { type: "facebook", data: { url: "RenaultLindaVista" } },
    { type: "instagram", data: { url: "renault_lv" } },
    {
      type: "whatsapp",
      data: { number: "8159984000", url: "https://wa.me/8159984000" },
    },
  ],
  services: [
    { title: "Prueba de manejo", image: "blk1.jpg", to: "/contacto" },
    {
      title: "Conoce nuestros modelos",
      image: "blk2.jpg",
      to: "/contacto?motivo=prueba",
    },
    {
      title: "Realiza tu",
      subtitle: "cita de servicio",
      renderTitle: true,
      image: "blk3.jpg",
      to: "/contacto?motivo=servicio",
    },
  ],
  contact: {
    text: "Para contactarnos por favor llena este sencillo formulario y no olvides explicar el motivo antes de hacer clic en el botón de Enviar. Pronto nos pondremos en contacto contigo para hacer un oportuno seguimiento de tu caso.",
    address:
      "Prolongacion Madero 4275 Ote Col.Fierro, Fierro, 64590 Monterrey, N.L.",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57539.85392387383!2d-100.3459935!3d25.6633089!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1fe71a6ae3e4eae6!2sRenault+Linda+Vista!5e0!3m2!1sen!2smx!4v1536677264337",
    options: [
      {
        type: "whatsapp",
        data: {
          text: "+81 5998 4000",
          number: "8159984000",
          url: "https://wa.me/8159984000",
        },
      },
    ],
  },
  footer: {
    address:
      "Prolongacion Madero 4275 Ote Col.Fierro, Fierro, 64590 Monterrey, N.L.",
    legal: "Aviso de Privacidad",
  },
  terms:
    "Imágenes de uso ilustrativo. Pecios expresados en Pesos Mexicanos. Precios sujetos a cambios sin previo aviso. Vigencia Septiembre 2022",
};
